import ko from 'knockout';
import { Modules } from "../data/modules";
import { ModuleMoverAlgorithm } from "./moduleMoverAlgorithm";
import { ModuleMoverOneThirdAlgorithm } from "./moduleMoverOneThirdAlgorithm";

export const Places = {
  RACK: 'rack',
  RESERVE: 'reserve',
  FINDER: 'finder'
}

export function ModuleMover(rack) {
  var self = this;
  self.Rack = rack;
  self.MoveArguments = { IsOneThirdMove: false };
  self.ModulesData = Modules;

  self.fromReserve = function (moduleIdInRack) {
    self.MoveArguments.From = {
      Place: Places.RESERVE,
      ModuleIdInRack: moduleIdInRack
    };

    return self;
  }

  self.fromRack = function (srcRowId, srcIndex, moduleContainerIndex) {
    if (arguments.length == 2) {
      return self.fromRackFullHeight(srcRowId, srcIndex);
    } else if (arguments.length == 3) {
      return self.fromRackOneThird(srcRowId, srcIndex, moduleContainerIndex);
    }
  };

  self.fromRackFullHeight = function (srcRowId, srcIndex) {
    self.MoveArguments.From = {
      Place: Places.RACK,
      RowId: srcRowId,
      Index: srcIndex
    };

    return self;
  }

  self.fromRackOneThird = function (srcRowId, srcIndex, moduleContainerIndex) {
    self.MoveArguments.IsOneThirdMove = true;

    self.MoveArguments.From = {
      Place: Places.RACK,
      RowId: srcRowId,
      Index: srcIndex,
      ModuleContainerIndex: moduleContainerIndex
    };

    return self;
  }

  self.fromFinder = function (moduleId) {
    self.MoveArguments.From = {
      Place: Places.FINDER,
      ModuleId: moduleId
    };

    return self;
  }

  self.toReserve = function () {
    self.MoveArguments.To = {
      Place: Places.RESERVE
    };

    return self;
  }

  self.toRack = function (destRowId, destIndex, moduleContainerIndex) {
    if (arguments.length == 2) {
      return self.toRackFullHeight(destRowId, destIndex);
    } else if (arguments.length == 3) {
      return self.toRackOneThird(destRowId, destIndex, moduleContainerIndex);
    }
  };

  self.toRackFullHeight = function (destRowId, destIndex) {
    self.MoveArguments.To = {
      Place: Places.RACK,
      RowId: destRowId,
      Index: destIndex
    };

    return self;
  }

  self.toRackOneThird = function (destRowId, destIndex, moduleContainerIndex) {
    self.MoveArguments.IsOneThirdMove = true;

    self.MoveArguments.To = {
      Place: Places.RACK,
      RowId: destRowId,
      Index: destIndex,
      ModuleContainerIndex: moduleContainerIndex
    };

    return self;
  }

  self.doMove = function () {
    var moduleMoverAlgorithm;

    if (self.MoveArguments.IsOneThirdMove) {
      moduleMoverAlgorithm = new ModuleMoverOneThirdAlgorithm(self.Rack, self.MoveArguments);
      moduleMoverAlgorithm.ModulesData = self.ModulesData;
    }
    else {
      moduleMoverAlgorithm = new ModuleMoverAlgorithm(self.Rack, self.MoveArguments);
    }

    self.Rack = moduleMoverAlgorithm.run();
  }
}