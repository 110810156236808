import ko from 'knockout';
import { AEModularViewModel } from "../modules/aemodularviewmodel";

$(document).ready(function () {
  $().dndPageScroll();
  $('[data-toggle="tooltip"]').tooltip();

  var viewModel = new AEModularViewModel();
  ko.applyBindings(viewModel);
});

