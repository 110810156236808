import ko from 'knockout';
import { Places } from "./moduleMover";
import { AEMUtilities } from "./utilities";
import { Modules } from "../data/modules";
import { Module } from './module';

export function ModuleMoverAlgorithm(rack, moveArguments) {
  var self = this;
  self.Rack = rack;
  self.MoveArguments = moveArguments;
  self.ModulesData = Modules;

  self.SrcRow = null;
  self.SrcIndex = null;
  self.DestRow = null;
  self.DestIndex = null;
  self.Module = null;

  self.run = function () {
    if (self.MoveArguments.From.Place === Places.RACK) {
      moveFromRack();
    }
    else if (self.MoveArguments.From.Place === Places.RESERVE) {
      moveFromReserve();
    }
    else if (self.MoveArguments.From.Place === Places.FINDER) {
      moveFromFinder();
    }
  }

  // General "Move From" functions
  function moveFromRack() {
    self.SrcRow = self.Rack.GetRowById(self.MoveArguments.From.RowId);
    self.SrcIndex = self.MoveArguments.From.Index;
    self.Module = self.SrcRow.Modules()[self.SrcIndex];

    if (self.MoveArguments.To.Place === Places.RACK) {
      self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
      self.DestIndex = self.MoveArguments.To.Index;

      moveFromRackToRack();
    }
    else if (self.MoveArguments.To.Place === Places.RESERVE) {
      moveFromRackToReserve();
    }
  }

  function moveFromReserve() {
    self.Module = self.Rack.ModuleReserve().GetModuleById(self.MoveArguments.From.ModuleIdInRack);

    if (self.MoveArguments.To.Place === Places.RACK) {
      self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
      self.DestIndex = self.MoveArguments.To.Index;

      moveFromReserveToRack();
    }
    else if (self.MoveArguments.To.Place === Places.RESERVE) {
      self.Rack;
    }
  }

  function moveFromFinder() {
    var moduleData = Modules.find(module => module.ModuleID === self.MoveArguments.From.ModuleId);

    if (moduleData) {
      self.Module = new Module(moduleData);

      if (self.MoveArguments.To.Place === Places.RACK) {
        self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
        self.DestIndex = self.MoveArguments.To.Index;

        moveFromFinderToRack();
      }
      else if (self.MoveArguments.To.Place === Places.RESERVE) {
        moveFromFinderToReserve();
      }
    }
  }

  // Move inside Rack
  function moveFromRackToRack() {
    if (!self.DestRow.ModuleFits(self.Module, self.DestIndex)) {
      return;
    }

    if (self.MoveArguments.From.RowId === self.MoveArguments.To.RowId) {
      moveModuleToSameRow();
    }
    else {
      moveModuleToDifferentRow();
    }
  }

  function moveModuleToSameRow() {
    if (self.SrcIndex < self.DestIndex) {
      moveModuleToSameRowToTheRight();
    }
    else {
      moveModuleToSameRowToTheLeft();
    }
  }

  function moveModuleToSameRowToTheRight() {
    var modulesToInsertInDestination = [self.Module];
    var modulesToInsertInSource = [];
    var modulesToInsertInReserve = [];
    var modulesToRemoveFromDestination = [];
    var unitsDisplacedAtDestination = 0;
    var unitsInsertedAtSource = 0;

    for (let i = self.DestIndex; i < self.DestRow.Modules().length; i++) {
      const m = self.DestRow.Modules()[i];
      modulesToRemoveFromDestination.push(m);
      unitsDisplacedAtDestination += m.Units;

      if (unitsDisplacedAtDestination <= self.Module.Units) {
        modulesToInsertInSource.push(m);
        unitsInsertedAtSource += m.Units;
      }

      if (unitsDisplacedAtDestination == self.Module.Units) {
        break;
      }
      else if (unitsDisplacedAtDestination > self.Module.Units) {
        modulesToInsertInReserve.push(m);

        var extraUnitsInDest = unitsDisplacedAtDestination - self.Module.Units;
        modulesToInsertInDestination = modulesToInsertInDestination.concat(AEMUtilities.GetBlankModules(extraUnitsInDest));
        break;
      }
    }

    if (self.Module.Units > unitsInsertedAtSource) {
      modulesToInsertInSource = modulesToInsertInSource.concat(AEMUtilities.GetBlankModules(self.Module.Units - unitsInsertedAtSource));
    }

    self.DestRow.DeleteModulesAt(self.DestIndex, modulesToRemoveFromDestination.length);
    self.DestRow.InsertModulesAt(self.DestIndex, modulesToInsertInDestination);

    self.DestRow.DeleteModulesAt(self.SrcIndex, 1);
    self.DestRow.InsertModulesAt(self.SrcIndex, modulesToInsertInSource);

    for (const m of modulesToInsertInReserve) {
      if (!m.IsBlank) {
        self.Rack.ModuleReserve().AddModule(m);
      }
    }
  }

  function moveModuleToSameRowToTheLeft() {
    var modulesToInsertInDestination = [self.Module];
    var modulesToInsertInSource = [];
    var modulesToInsertInReserve = [];
    var modulesToRemoveFromDestination = [];
    var unitsDisplacedAtDestination = 0;
    var unitsInsertedAtSource = 0;

    for (let i = self.DestIndex; i < self.SrcIndex; i++) {
      const m = self.DestRow.Modules()[i];
      modulesToRemoveFromDestination.push(m);
      unitsDisplacedAtDestination += m.Units;

      if (unitsDisplacedAtDestination <= self.Module.Units) {
        modulesToInsertInSource.push(m);
        unitsInsertedAtSource += m.Units;
      }

      if (unitsDisplacedAtDestination == self.Module.Units) {
        break;
      }
      else if (unitsDisplacedAtDestination > self.Module.Units) {
        modulesToInsertInReserve.push(m);

        var extraUnitsInDest = unitsDisplacedAtDestination - self.Module.Units;
        modulesToInsertInDestination = modulesToInsertInDestination.concat(AEMUtilities.GetBlankModules(extraUnitsInDest));
        break;
      }
    }

    var overlaps = unitsDisplacedAtDestination < self.Module.Units;
    if (!overlaps && self.Module.Units > unitsInsertedAtSource) {
      modulesToInsertInSource = modulesToInsertInSource.concat(AEMUtilities.GetBlankModules(self.Module.Units - unitsInsertedAtSource));
    }

    self.DestRow.DeleteModulesAt(self.SrcIndex, 1);
    self.DestRow.InsertModulesAt(self.SrcIndex, modulesToInsertInSource);

    self.DestRow.DeleteModulesAt(self.DestIndex, modulesToRemoveFromDestination.length);
    self.DestRow.InsertModulesAt(self.DestIndex, modulesToInsertInDestination);

    for (const m of modulesToInsertInReserve) {
      if (!m.IsBlank) {
        self.Rack.ModuleReserve().AddModule(m);
      }
    }
  }

  function moveModuleToDifferentRow() {
    var modulesToInsertInDestination = [self.Module];
    var modulesToInsertInSource = [];
    var modulesToInsertInReserve = [];
    var modulesToRemoveFromDestination = [];
    var unitsDisplacedAtDestination = 0;
    var unitsInsertedAtSource = 0;

    for (let i = self.DestIndex; i < self.DestRow.Modules().length; i++) {
      const m = self.DestRow.Modules()[i];
      modulesToRemoveFromDestination.push(m);
      unitsDisplacedAtDestination += m.Units;

      if (unitsDisplacedAtDestination <= self.Module.Units) {
        modulesToInsertInSource.push(m);
        unitsInsertedAtSource += m.Units;
      }

      if (unitsDisplacedAtDestination == self.Module.Units) {
        break;
      }
      else if (unitsDisplacedAtDestination > self.Module.Units) {
        modulesToInsertInReserve.push(m);

        var extraUnitsInDest = unitsDisplacedAtDestination - self.Module.Units;
        modulesToInsertInDestination = modulesToInsertInDestination.concat(AEMUtilities.GetBlankModules(extraUnitsInDest));
        break;
      }
    }

    if (self.Module.Units > unitsInsertedAtSource) {
      modulesToInsertInSource = modulesToInsertInSource.concat(AEMUtilities.GetBlankModules(self.Module.Units - unitsInsertedAtSource));
    }

    self.DestRow.DeleteModulesAt(self.DestIndex, modulesToRemoveFromDestination.length);
    self.DestRow.InsertModulesAt(self.DestIndex, modulesToInsertInDestination);

    self.SrcRow.DeleteModulesAt(self.SrcIndex, 1);
    self.SrcRow.InsertModulesAt(self.SrcIndex, modulesToInsertInSource);

    for (const m of modulesToInsertInReserve) {
      if (!m.IsBlank) {
        self.Rack.ModuleReserve().AddModule(m);
      }
    }
  }

  // Move to Rack from outside
  function moveToRackFromElsewhere() {
    if (!self.DestRow.ModuleFits(self.Module, self.DestIndex))
      return false;

    var modulesToInsertInDestRow = [self.Module];
    var modulesToRemoveFromDestRow = [];
    var unitsDisplacedAtDestination = 0;

    for (let i = self.DestIndex; i < self.DestRow.Modules().length; i++) {
      const m = self.DestRow.Modules()[i];
      modulesToRemoveFromDestRow.push(m);
      unitsDisplacedAtDestination += m.Units;

      if (unitsDisplacedAtDestination == self.Module.Units) {
        break;
      }
      else if (unitsDisplacedAtDestination > self.Module.Units) {
        var extraUnits = unitsDisplacedAtDestination - self.Module.Units;
        modulesToInsertInDestRow = modulesToInsertInDestRow.concat(AEMUtilities.GetBlankModules(extraUnits));
        break;
      }
    }

    self.DestRow.DeleteModulesAt(self.DestIndex, modulesToRemoveFromDestRow.length);
    self.DestRow.InsertModulesAt(self.DestIndex, modulesToInsertInDestRow);

    for (const m of modulesToRemoveFromDestRow) {
      if (!m.IsBlank) {
        self.Rack.ModuleReserve().AddModule(m);
      }
    }

    return true;
  }

  function moveFromFinderToRack() {
    moveToRackFromElsewhere();
  }

  function moveFromReserveToRack() {
    if (moveToRackFromElsewhere()) {
      self.Rack.ModuleReserve().DeleteModule(self.Module);
    }
  }

  // Move to Reserve
  function moveFromRackToReserve() {
    self.SrcRow.DeleteModulesAt(self.SrcIndex, 1);
    self.SrcRow.InsertModulesAt(self.SrcIndex, AEMUtilities.GetBlankModules(self.Module.Units));
    self.Rack.ModuleReserve().AddModule(self.Module);
  }

  function moveFromFinderToReserve() {
    self.Rack.ModuleReserve().AddModule(self.Module);
  }
}