import ko from 'knockout';
import { Rack } from "./rack";
import { AEMUtilities } from "./utilities";
import { Modules } from "../data/modules";
import { Cases } from "../data/cases";
import { CaseMaterial } from '../modules/aemcase';
import { PresetSystems } from "../data/systems";
import { Module } from './module';
import { ModuleMover, Places } from './moduleMover';
import copy from 'copy-to-clipboard';

export function AEModularViewModel() {
  var self = this;

  // Data
  self.cases = ko.observableArray(Cases);
  self.caseMaterials = [
    CaseMaterial.ACRYLIC_RED, 
    CaseMaterial.BAMBOO, 
    CaseMaterial.WALNUT,
    CaseMaterial.ACRYLIC_ORANGE,
    CaseMaterial.ACRYLIC_PINK,
    CaseMaterial.ACRYLIC_BLUE,
    CaseMaterial.ACRYLIC_NEONGREEN,
    CaseMaterial.ACRYLIC_DARKVIOLETSEMITRANSPARENT,
    CaseMaterial.ACRYLIC_NEONORANGESEMITRANSPARENT,
    CaseMaterial.ACRYLIC_VIOLETSEMITRANSPARENT,
    CaseMaterial.ACRYLIC_YELLOW,
    CaseMaterial.ACRYLIC_MATTEBLACK
  ];

  // Rack interface
  self.currentRack = ko.observable(new Rack());

  self.selectedCase = ko.observable();
  self.selectedModule = ko.observable();
  self.draggingModule = ko.observable();

  self.diyModuleUnits = ko.observable(1);
  self.diyModuleText = ko.observable("");

  self.UseCopyPaste = ko.observable(AEMUtilities.IsIOS());
  self.SaveLoadText = ko.observable("");

  // Module finder
  self.showDiscontinued = ko.observable(false);
  self.ModuleFinderFilter = ko.observable("NEW");
  self.FilteredModules = ko.computed(function () {
    var newData =
      Modules
        .filter(module => {
          return self.showDiscontinued() || !module.Discontinued;
        })
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }

          if (a.Name > b.Name) {
            return 1;
          }

          return 0;
        });

    var newList = [];
    newData.forEach(moduleData => {
      newList.push(new Module(moduleData));
    });

    if (self.ModuleFinderFilter() === "ALL") {
      return newList;
    } else {
      return ko.utils.arrayFilter(newList, function (module) {
        return module.Categories.indexOf(self.ModuleFinderFilter()) !== -1;
      });
    }
  });

  // Actions
  self.AddCaseToRack = function () {
    self.currentRack().AddCase(self.selectedCase());
  };

  self.AddModuleToReserve = function (module) {
    self.currentRack().ModuleReserve().AddModule(new Module(module));
    $('#reserve-wrapper').collapse('show');
  };

  self.AddDIYModuleToReserve = function () {
    var module = AEMUtilities.GetDIYModule(self.diyModuleUnits(), self.diyModuleText());
    self.currentRack().ModuleReserve().AddModule(module);
    $('#reserve-wrapper').collapse('show');
  };


  // One third module placeholder Drag/Drop events
  self.OneThirdModulePlaceholderDragOver = function (module, event) {
    // prevent default to allow drop
    event.preventDefault();
    event.originalEvent.dataTransfer.dropEffect = 'move';
  };

  self.OneThirdModulePlaceholderDragEnter = function (module, event) {
    event.originalEvent.target.parentElement.style.opacity = "0.3";
  };

  self.OneThirdModulePlaceholderDragLeave = function (module, event) {
    event.originalEvent.target.parentElement.style.opacity = "0";
  };

  self.OneThirdModulePlaceholderDrop = function (module, event, parentRow, parentRowIndex, moduleContainerIndex) {
    // prevent default action (open as link for some elements)
    event.preventDefault();
    event.originalEvent.target.parentElement.style.opacity = "0";
    //alert('parentRow : ' + parentRow.ID + ", parentRowIndex: " + parentRowIndex + ", moduleContainerIndex: " + moduleContainerIndex() );

    var droppedModule = self.draggingModule;
    var sourceData = JSON.parse(event.originalEvent.dataTransfer.getData("text"));

    var moduleMover = new ModuleMover(self.currentRack());

      if (sourceData.source === Places.RACK) {
        moduleMover.fromRack(sourceData.RowId, sourceData.index).toRack(parentRow.ID, parentRowIndex,  moduleContainerIndex()).doMove();
      }
      else if (sourceData.source === Places.RESERVE) {
        moduleMover.fromReserve(droppedModule.ID).toRack(parentRow.ID, parentRowIndex,  moduleContainerIndex()).doMove();
      }
      else if (sourceData.source === Places.FINDER) {
        moduleMover.fromFinder(droppedModule.ModuleID).toRack(parentRow.ID, parentRowIndex,  moduleContainerIndex()).doMove();
      }

  };


  // Other Drag & Drop events
  self.ModuleDragStart = function (module, event) {
    $('.module-actions').css('visibility', 'hidden');
    $('.bright-module-overlay').css('visibility', 'hidden');

    if (module.IsOneThird) {
      $('.one-third-container').css('visibility', 'visible');
    }

    self.draggingModule = module;
  };

  self.ModuleDragEnd = function (module, event) {
    self.DragDropEnded();
  };

  self.ModuleDragEnter = function (module, event, parentRow, index) {
    module.IsDraggedOver(parentRow.ModuleFits(self.draggingModule, index()));
  };

  self.MoveModuleToReserve = function (module, event, parentRow, index) {
    var moduleMover = new ModuleMover(self.currentRack());
    moduleMover.fromRack(parentRow.ID, index()).toReserve().doMove();
    $('#reserve-wrapper').collapse('show');
  };

  self.DropModuleInReserve = function (reserve, event) {
    self.DragDropEnded();

    var droppedModule = self.draggingModule;
    var sourceData = JSON.parse(event.originalEvent.dataTransfer.getData("text"));

    droppedModule.IsDragging(false);
    self.currentRack().ModuleReserve().IsDraggedOver(false);

    try {
      var moduleMover = new ModuleMover(self.currentRack());

      if (sourceData.source === Places.RACK) {
        moduleMover.fromRack(sourceData.RowId, sourceData.index).toReserve().doMove();

      }
      else if (sourceData.source === Places.FINDER) {
        moduleMover.fromFinder(droppedModule.ModuleID).toReserve().doMove();
      }
    }
    catch (exception) {
      alert("Couldn't move that module : " + exception.message);
    }
  };

  self.DropModuleInRack = function (targetModule, event, destRow, index) {
    self.DragDropEnded();

    var droppedModule = self.draggingModule;
    var destIndex = index();

    targetModule.IsDraggedOver(false);
    droppedModule.IsDragging(false);

    if (!destRow.ModuleFits(droppedModule, destIndex)) {
      return;
    }

    var sourceData = JSON.parse(event.originalEvent.dataTransfer.getData("text"));

    try {
      var moduleMover = new ModuleMover(self.currentRack());

      if (sourceData.source === Places.RACK) {
        moduleMover.fromRack(sourceData.RowId, sourceData.index).toRack(destRow.ID, destIndex).doMove();
      }
      else if (sourceData.source === Places.RESERVE) {
        moduleMover.fromReserve(droppedModule.ID).toRack(destRow.ID, destIndex).doMove();
      }
      else if (sourceData.source === Places.FINDER) {
        moduleMover.fromFinder(droppedModule.ModuleID).toRack(destRow.ID, destIndex).doMove();
      }
    }
    catch (exception) {
      alert("Couldn't move that module : " + exception.message);
    }
  };

  self.DragDropEnded = function () {
    $('.module-actions').css('visibility', '');
    $('.bright-module-overlay').css('visibility', '');
    $('.one-third-container').css('visibility', 'hidden');
  };


  // Load/Save rack
  self.SaveRack = function () {
    var data = self.currentRack().SerializeToJson();
    AEMUtilities.Download(data, self.currentRack().Name() + '.json', 'application/json');
  };

  self.LoadRack = function (file) {
    var reader = new FileReader(file);

    reader.onload = function (event) {
      var newRack = Rack.DeserializeFromJson(event.target.result);
      self.currentRack(newRack);
    };

    reader.readAsText(file);
  };

  self.LoadPreset = function (name) {
    var system = Rack.DeserializeFromJson(PresetSystems[name]);
    self.currentRack(system);
  };

  self.DisplayCopyPaste = function () {
    var data = self.currentRack().SerializeToJson();
    self.SaveLoadText(data);
  };

  self.CopyRackToClipBoard = function () {
    copy(self.SaveLoadText());
  };

  self.LoadRackFromText = function () {
    var newRack = Rack.DeserializeFromJson(self.SaveLoadText());
    self.currentRack(newRack);
  };
};
