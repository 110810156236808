import ko from 'knockout';
import { Places } from "./moduleMover";
import { AEMUtilities } from "./utilities";
import { Modules } from "../data/modules";
import { Module } from './module';

export function ModuleMoverOneThirdAlgorithm(rack, moveArguments) {
  var self = this;
  self.ModulesData = Modules;

  self.Rack = rack;
  self.MoveArguments = moveArguments;

  self.SrcRow = null;
  self.SrcIndex = null;
  self.SrcModuleContainerIndex = null;
  self.SrcContainerModule = null;

  self.DestRow = null;
  self.DestIndex = null;
  self.DestModuleContainerIndex = null;
  self.DestContainerModule = null;

  self.Module = null;

  self.run = function () {
    if (self.MoveArguments.From.Place === Places.RACK) {
      moveFromRack();
    }
    else if (self.MoveArguments.From.Place === Places.RESERVE) {
      moveFromReserve();
    }
    else if (self.MoveArguments.From.Place === Places.FINDER) {
      moveFromFinder();
    }
  }

  // General "Move From" functions
  function moveFromRack() {
    self.SrcRow = self.Rack.GetRowById(self.MoveArguments.From.RowId);
    self.SrcIndex = self.MoveArguments.From.Index;
    self.SrcModuleContainerIndex = self.MoveArguments.From.ModuleContainerIndex;

    var srcContainerModule = self.SrcRow.Modules()[self.SrcIndex];

    if (!srcContainerModule.IsOneThirdContainer) {
      return;
    }

    self.SrcContainerModule = srcContainerModule;
    self.Module = self.SrcContainerModule.ContainedModules()[self.SrcModuleContainerIndex];

    if (self.MoveArguments.To.Place === Places.RACK) {
      self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
      self.DestIndex = self.MoveArguments.To.Index;
      self.DestModuleContainerIndex = self.MoveArguments.To.ModuleContainerIndex;

      moveFromRackToRack();
    }
    else if (self.MoveArguments.To.Place === Places.RESERVE) {
      moveFromRackToReserve();
    }
  }

  function moveFromReserve() {
    self.Module = self.Rack.ModuleReserve().GetModuleById(self.MoveArguments.From.ModuleIdInRack);

    if (self.MoveArguments.To.Place === Places.RACK) {
      self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
      self.DestIndex = self.MoveArguments.To.Index;
      self.DestModuleContainerIndex = self.MoveArguments.To.ModuleContainerIndex;

      moveFromReserveToRack();
    }
    else if (self.MoveArguments.To.Place === Places.RESERVE) {
      self.Rack;
    }
  }

  function moveFromFinder() {
    var moduleData = self.ModulesData.find(module => module.ModuleID === self.MoveArguments.From.ModuleId);

    if (moduleData) {
      self.Module = new Module(moduleData);

      if (self.MoveArguments.To.Place === Places.RACK) {
        self.DestRow = self.Rack.GetRowById(self.MoveArguments.To.RowId);
        self.DestIndex = self.MoveArguments.To.Index;
        self.DestModuleContainerIndex = self.MoveArguments.To.ModuleContainerIndex;

        moveFromFinderToRack();
      }
      else if (self.MoveArguments.To.Place === Places.RESERVE) {
        moveFromFinderToReserve();
      }
    }
  }

  // Move inside Rack
  function moveFromRackToRack() {
    var destContainerModule = self.DestRow.Modules()[self.DestIndex];

    if (!destContainerModule.IsOneThirdContainer) {
      return;
    }

    var destModule = destContainerModule.ContainedModules()[self.DestModuleContainerIndex];

    if (destModule === null) {
      self.SrcContainerModule.ContainedModules()[self.SrcModuleContainerIndex] = null;
    }
    else {
      self.SrcContainerModule.ContainedModules()[self.SrcModuleContainerIndex] = destModule;
    }

    destContainerModule.ContainedModules()[self.DestModuleContainerIndex] = self.Module;
  }

  // Move to Rack from outside
  function moveToRackFromElsewhere() {
    var containerModule = self.DestRow.Modules()[self.DestIndex];

    if (!containerModule.IsOneThirdContainer) {
      return false;
    }

    if (containerModule.ContainedModules()[self.DestModuleContainerIndex]) {
      self.Rack.ModuleReserve().AddModule(containerModule.ContainedModules()[self.DestModuleContainerIndex]);
    }

    containerModule.ContainedModules.splice(self.DestModuleContainerIndex, 1, self.Module);

    return true;
  }

  function moveFromFinderToRack() {
    moveToRackFromElsewhere();
  }

  function moveFromReserveToRack() {
    if (moveToRackFromElsewhere()) {
      self.Rack.ModuleReserve().DeleteModule(self.Module);
    }
  }

  // Move to Reserve
  function moveFromRackToReserve() {
    self.SrcContainerModule.ContainedModules()[self.SrcModuleContainerIndex] = null;
    self.Rack.ModuleReserve().AddModule(self.Module);
  }

  function moveFromFinderToReserve() {
    self.Rack.ModuleReserve().AddModule(self.Module);
  }
}