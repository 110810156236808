import ko from 'knockout';
import { Module } from "./module";
import { BlankModule } from "../data/modules";
import saveAs from 'file-saver';

export var AEMUtilities = {};

AEMUtilities.GetBlankModule = function () {
  return new Module(BlankModule);
}

AEMUtilities.GetDIYModule = function (units, text) {
  var DIYModule = {
    ID: 0,
    Name: text,
    Units: units,
    IsDIY: true,
    Image: 'images/modules/blank' + units + '.png',
    Discontinued: false
  };

  return new Module(DIYModule);
}

AEMUtilities.GetBlankModules = function (number) {
  var blanks = [];

  for (var i = 0; i < number; i++) {
    blanks.push(AEMUtilities.GetBlankModule());
  }

  return blanks;
}

AEMUtilities.Download = function (data, filename, type) {
  var file = new Blob([data], { type: type });
  saveAs(file, filename);
}

AEMUtilities.IsIOS = function(){
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
}