import ko from 'knockout';
import { AEMUtilities } from "./utilities";
import { AEMCase } from "./aemcase";
import { CaseRow } from "./caserow";
import { ModuleReserve } from "./modulereserve";
import { Module } from './module';
import { Modules } from "../data/modules";
import { Cases } from "../data/cases";
import { GetCase } from "../data/cases";

export function Rack() {
  var self = this;
  self.Name = ko.observable('My Rack');
  self.Cases = ko.observableArray();
  self.ModuleReserve = ko.observable(new ModuleReserve());

  self.SerializationVersion = 1;

  self.PriceHasErrors = ko.observable(false);
  self.TotalPrice = ko.computed(function () {
    var total = 0;
    self.PriceHasErrors(false);

    for (var i = 0; i < self.Cases().length; i++) {
      var aemcase = self.Cases()[i];

      if (aemcase.Price && aemcase.CablePrice) {
        total += aemcase.Price + aemcase.CablePrice;
      }
      else {
        var dataCase = GetCase(aemcase.Rows, aemcase.Units);

        if (dataCase) {
          aemcase.Price = dataCase.Price;
          aemcase.CablePrice = dataCase.CablePrice;

          total += aemcase.Price + aemcase.CablePrice;
        }
        else {
          self.PriceHasErrors(true);
        }
      }

      for (var j = 0; j < aemcase.Content().length; j++) {
        var row = aemcase.Content()[j];
        for (let k = 0; k < row.Modules().length; k++) {
          const module = row.Modules()[k];

          if (!module.IsBlank && !module.IsDIY) {
            if (!module.Discontinued && module.Price) {
              total += module.Price;
            }
            else {
              self.PriceHasErrors(true);
            }
          }
        }
      }
    }

    return total;
  });

  self.PowerHasErrors = ko.observable(false);

  self.TotalPowerConsumption = ko.computed(function () {
    var total = 0;
    self.PowerHasErrors(false);

    for (var i = 0; i < self.Cases().length; i++) {
      var aemcase = self.Cases()[i];

      for (var j = 0; j < aemcase.Content().length; j++) {
        var row = aemcase.Content()[j];
        for (let k = 0; k < row.Modules().length; k++) {
          const module = row.Modules()[k];

          if (!module.IsBlank && !module.IsDIY) {
            if (typeof(module.PowerConsumption) !== 'undefined') {
              total += module.PowerConsumption;
            }
            else {
              self.PowerHasErrors(true);
            }
          }
        }
      }
    }

    return total;
  });

  self.TotalPowerCapacity = ko.computed(function () {
    var total = 0;

    for (var i = 0; i < self.Cases().length; i++) {
      var aemcase = self.Cases()[i];
      total += aemcase.PowerCapacity;
      
      for (var j = 0; j < aemcase.Content().length; j++) {
        var row = aemcase.Content()[j];
        for (let k = 0; k < row.Modules().length; k++) {
          const module = row.Modules()[k];

          if (!module.IsBlank && !module.IsDIY) {
            if (module.PowerCapacity) {
              total += module.PowerCapacity;
            }
          }
        }
      }
    }

    return total;
  });

  self.PowerCapacityExceeded = ko.computed(function () {
    return self.TotalPowerConsumption() > self.TotalPowerCapacity();
  });

  self.GetRowById = function (id) {
    for (var i = 0; i < self.Cases().length; i++) {
      var aemcase = self.Cases()[i];
      for (var j = 0; j < aemcase.Content().length; j++) {
        var row = aemcase.Content()[j];
        if (row.ID == id) {
          return row;
        }
      }
    }
  };

  self.AddCase = function (aemcase) {
    var newCase = new AEMCase(aemcase);
    newCase.Order(self.Cases().length);
    self.Cases.push(newCase);
  };

  self.MoveCaseUp = function (currentCase) {
    if (currentCase.Order() > 0) {
      var caseToSwitch = self.Cases()[currentCase.Order() - 1];
      caseToSwitch.Order(caseToSwitch.Order() + 1);
      currentCase.Order(currentCase.Order() - 1);

      self.Cases.splice(currentCase.Order(), 2, currentCase, caseToSwitch);
    }
  };

  self.MoveCaseDown = function (currentCase) {
    if (currentCase.Order() < self.Cases().length - 1) {
      var caseToSwitch = self.Cases()[currentCase.Order() + 1];
      caseToSwitch.Order(caseToSwitch.Order() - 1);
      currentCase.Order(currentCase.Order() + 1);

      self.Cases.splice(caseToSwitch.Order(), 2, caseToSwitch, currentCase);
    }
  };

  self.DeleteCase = function (currentCase) {
    self.Cases.splice(currentCase.Order(), 1);
    self.ResetOrder();
  };

  self.ResetOrder = function () {
    for (var i = 0; i < self.Cases().length; i++) {
      self.Cases()[i].Order(i);
    }
  };

  self.SerializeToJson = function () {
    return ko.toJSON(self);
  };

  self.ToString = function () {
    var content = [];

    for (const aemCase of self.Cases().sort((a, b) => a.Order() - b.Order())) {
      for (const row of aemCase.Content()) {
        content.push(row.ToNameArray());
      }
    }

    return JSON.stringify(content);
  };
}

Rack.DeserializeFromJson = function (json) {
  var jsonRack;

  try {
    jsonRack = JSON.parse(json);
  }
  catch (e) {
    alert("Error with your file: " + e);
    return;
  }

  var newRack = new Rack();
  var errors = [];

  newRack.Name(jsonRack.Name);

  jsonRack.Cases.forEach(aemCase => {
    var newCase = new AEMCase(aemCase);
    newCase.Order(aemCase.Order);
    aemCase.Content.forEach((row, rowIndex) => {
      var rowContent = [];
      row.Modules.forEach((module, moduleIndex) => {
        var moduleData;
        if (module.IsBlank) {
          moduleData = AEMUtilities.GetBlankModule();
        }
        else if (module.IsDIY) {
          moduleData = module;
        }
        else {
          // Finding the module data from its ID only (don't trust the json)
          moduleData = Modules.find(function (element) {
            return element.ModuleID === module.ModuleID || element.Name == module.Name;
          });
        }

        if (moduleData) {
          rowContent.push(new Module(moduleData));
        }
        else {
          for (var i = 0; i < module.Units; i++) {
            rowContent.push(new Module(AEMUtilities.GetBlankModule()));
          }

          errors.push("Couldn't find module " + module.Name);
        }
      });

      var newRow = new CaseRow(aemCase);
      newRow.Modules(rowContent);
      newCase.Content.splice(rowIndex, 1, newRow);
    });
    newRack.Cases.push(newCase);
  });

  newRack.ResetOrder();

  if (jsonRack.ModuleReserve) {
    for (const m of jsonRack.ModuleReserve.Modules) {
      newRack.ModuleReserve().AddModule(new Module(m));
    }
  }

  errors.forEach(error => {
    console.log(error);
  });
  return newRack;
};