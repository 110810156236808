import ko from 'knockout';
import uuid from 'uuid/v4';

export function Module(module) {
  var self = this;

  // Module data
  self.ID = uuid(); // ID of the specific individual module in the rack
  self.ModuleID = module.ModuleID; //ID of the module in the metadata
  self.Name = module.Name;
  self.Units = module.Units;
  self.Image = module.Image;
  self.Discontinued = module.Discontinued;
  self.WikiLink = module.WikiLink;
  self.ShopLink = module.ShopLink;
  self.Manufacturer = module.Manufacturer;
  self.Categories = module.Categories;
  self.IsBlank = module.Name === 'BLANK';
  self.Price = module.Price;
  self.PowerConsumption = module.PowerConsumption; // mA
  self.PowerCapacity = module.PowerCapacity // mA
  self.IsOneThird = module.IsOneThird;
  self.IsOneThirdContainer = module.IsOneThirdContainer;
  if(module.IsOneThirdContainer)
  {
    self.ContainedModules = ko.observableArray([null, null, null]);
  }

  // Interface data
  self.IsDIY = module.IsDIY;
  self.Draggable = !self.IsBlank;
  self.IsDragging = ko.observable(false);
  self.IsDraggedOver = ko.observable(false);
  self.DiyTextRotate = module.Name.length > (module.Units * 6);

  // Events
  self.DragStart = function (module, event, source, row, index, dragImageElement) {
    self.IsDragging(true);

    var data = {
      'source': source
    };

    if (row) {
      data.RowId = row.ID;
    }

    if (index) {
      data.index = index();
    }

    event.originalEvent.dataTransfer.setData("text", JSON.stringify(data));
  };

  self.DragEnd = function (module, event) {
    self.IsDragging(false);
  };

  self.DragOver = function (module, event) {
    // prevent default to allow drop
    event.preventDefault();
    event.originalEvent.dataTransfer.dropEffect = 'move';
  };

  self.DragEnter = function (module, event, parentRow, index) {
    // prevent default to allow drop
    event.preventDefault();
  };

  self.DragLeave = function (module, event) {
    self.IsDraggedOver(false);
  };
}