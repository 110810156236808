import ko from 'knockout';
import uuid from 'uuid/v4';
import { AEMUtilities } from "./utilities";

export function CaseRow(aemcase) {
  var self = this;

  self.ID = uuid();
  self.Units = aemcase.Units;
  self.Modules = ko.observableArray(AEMUtilities.GetBlankModules(aemcase.Units));

  self.ModuleFits = function (module, index) {
    var availableUnits = 0;

    for (var i = index; i < self.Modules().length; i++) {
      availableUnits += self.Modules()[i].Units;
    }

    return module.Units <= availableUnits;
  };

  self.ReplaceModuleWithBlanks = function (module) {
    var index = self.Modules.indexOf(module);
    var blanks = AEMUtilities.GetBlankModules(module.Units);
    var args = [index, 1].concat(blanks);
    self.Modules.splice.apply(self.Modules, args);
  };

  self.MoveModule = function (oldIndex, newIndex) {
    var moduleToMove = self.Modules.splice(oldIndex, 1);
    self.Modules.splice(newIndex, 0, moduleToMove[0]);
  }

  self.DeleteModulesAt = function (index, number) {
    self.Modules.splice(index, number);
  }

  self.InsertModulesAt = function (index, modules) {
    var args = [index, 0].concat(modules);
    self.Modules.splice.apply(self.Modules, args);
  }

  self.GetUnitCount = function () {
    return self.Modules().reduce((a, b) => a + b, 0);
  };

  self.IsValid = function () {
    return self.GetUnitCount() === self.Units;
  };

  self.ToNameArray = function () {
    return self.Modules().map(function (m) {
      if (m.IsOneThirdContainer) {
        return m.Name + JSON.stringify(m.ContainedModules().map(m2 => m2 ? m2.Name : 'NULL'));
      }
      else {
        return m.Name;
      }
    });
  }
}