import ko from 'knockout';

export function ModuleReserve() {
  var self = this;
  self.IsDraggedOver = ko.observable(false);
  self.Modules = ko.observableArray();

  self.AddModule = function (module) {
    self.Modules.push(module);
  };

  self.DeleteModule = function (module) {
    self.Modules.remove(module);
  };

  self.GetModuleById = function (ID) {
    return self.Modules().find(m => m.ID == ID);
  };

  self.DragOver = function (module, event) {
    event.preventDefault();
    event.originalEvent.dataTransfer.dropEffect = 'move';
  };

  self.DragEnter = function (reserve, event) {
    event.preventDefault();
    self.IsDraggedOver(true);
  };

  self.DragLeave = function (reserve, event) {
    self.IsDraggedOver(false);
  };

}